import { Route, Routes, HashRouter } from "react-router-dom";
import "./App.css";
import { ROLE, routes } from "./constants";
import { Home } from "./pages/Home";
import { MasterResult, MasterUsers } from "./pages/Master";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import { Profile } from "./pages/Profile";
import { BidHistory } from "./pages/BidHistory";
import { Result } from "./pages/Result";
import { PlaceBid } from "./pages/PlaceBid";
import { createContext, useContext, useLayoutEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import Loader from "./components/Loader/Loader";
import { getUserDetail } from "./api/api";
import Agentform from "./pages/Agentform";
import {MakeAgent} from "./pages/MakeAgent";
import ReferralCode from "./pages/ReferralCode";
import Notification from "./pages/Notification";
import { AgentOrAdmin } from "./pages/AgentOrAdmin";
import BottomNavbar from "./components/DrawerNav/BottomNavbar";
// import BG from '@/assets/image/bg.png'

const ProfileContext = createContext<any>(null);
export interface IUser {
    amount: number;
    email: string;
    name: string;
    role: ROLE;
    phone: string;
    agentPhone: string;
    agentName: string;
}

function App() {
    const [user, setUser] = useState<null | IUser>(null);
    const [isLoading, setIsLoading] = useState(true);
    //for Production
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
    const fetchCurrentUser = async() =>{
        try {
            setIsLoading(true);
            const user = await getUserDetail();
            setUser({
                amount: user.data.amount,
                email: user.data.email,
                name: user.data.name || user.data.email.split("@")[0],
                phone: user.data.phone,
                role: user.data.role,
                agentName: user.data.agent?.name || 'Master',
                agentPhone: user.data.agent?.phone || '+9779707559773'
            });
        } catch (error) {
            console.log(`Error fetching user: ${error}`);
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
        } finally {
            setIsLoading(false);
        }
    }

    useLayoutEffect(() => {
        fetchCurrentUser()
    }, []);
    return (
        <div className="relative flex bg-black justify-center items-center">
            <div className="min-h-screen w-full bg-gradient-to-br from-[#0c160c] to-[#032203] oleo-script">
                {isLoading ? (
                    <Loader />
                ) : (
                    <HashRouter>
                        <Toaster
                        toastOptions={{
                            className: "text-sm",
                            duration: 5000,
                            style: {
                              maxWidth: "300px",
                              padding: "12px 24px",
                              backgroundImage: "url('/frontend/frame/toast-frame.png')",
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "100% 100%",
                            },
                          }}
                          position="top-center"
                            containerStyle={{
                                top: 20,
                                left: 20,
                                bottom: 20,
                                right: 20,
                            }}
                        />
                        <ProfileContext.Provider value={{ user, setUser, fetchCurrentUser }}>
                            <Routes>
                                <Route path={routes.INDEX} element={<Home />} />
                                <Route path={routes.LOGIN} element={<Login />} />
                                <Route path={routes.PROFILE} element={<Profile />} />
                                <Route path={routes.BID_HISTORY} element={<BidHistory />} />
                                <Route path={`${routes.PLACE_BID}/:city`} element={<PlaceBid />} />
                                <Route path={routes.RESULT} element={<Result />} />
                                <Route path={routes.SIGNUP} element={<Signup />} />
                                <Route path={`${routes.SIGNUP}/:referCode`} element={<Signup />} />
                                <Route path={routes.MASTER_RESULT} element={<MasterResult />} />
                                <Route path={routes.MASTER_USER} element={<MasterUsers />} />
                                <Route path={routes.AGENT} element={<AgentOrAdmin />} />
                                <Route path={routes.ADMIN} element={<AgentOrAdmin />} />
                                <Route path={routes.AGENT_FORM} element={<Agentform />} />
                                <Route path={routes.MAKE_AGENT} element={<MakeAgent />} />
                                <Route path={routes.REFERRAL} element={<ReferralCode />} />
                                <Route path={routes.NOTIFICATION} element={<Notification />} />
                                {/* <Route path="*" element={<Navigate to={routes.INDEX} replace />} /> */}
                            </Routes>
                        </ProfileContext.Provider>
                        <div className="h-32 pb-10 w-full" />
                        <BottomNavbar/>
                    </HashRouter>
                )}
            </div>
        </div>
    );
}

export const useProfileContext = () => {
    return useContext(ProfileContext);
};

export default App;
